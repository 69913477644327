<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="menuTypes.length" :data="menuTypes">
        <el-table-column min-width="300">
          <template #header>
            <span> Menu Types </span>
          </template>
          <template v-slot="scope">
            <p class="text-bold">{{ formatToTitleCase(scope.row.name) }}</p>
            <p style="color: var(--eden-grey-primary)">
              {{ formatToTitleCase(scope.row.description) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template #header>
            <span> Meal Price </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.meal_price) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template #header>
            <span> Delivery Price </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.delivery_price) }}</p>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No menu types'" />
    </template>
  </div>
</template>

<script>
export default {
  name: "IndividualMenuType",
  data() {
    return {
      loading: false,
      pageData: [],
    };
  },
  computed: {
    title() {
      return `${this.menuTypes.length} Menu Types`;
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
  },
};
</script>
